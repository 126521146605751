<script>
import router from '@/router';
import { layoutComputed } from '@/state/helpers';

import NavBar from '@/components/nav-bar.vue';
import SideBar from '@/components/side-bar.vue';
import RightBar from '@/components/right-bar.vue';
import Footer from '@/components/footer.vue';

/**
 * Vertical layout
 */
export default {
  components: {
    NavBar, SideBar, RightBar, Footer,
  },
  data() {
    return {
      type: this.$store ? this.$store.state.layout.leftSidebarType : null || null,
      width: this.$store ? this.$store.state.layout.layoutWidth : null || null,
      isMenuCondensed: false,
    };
  },
  computed: {
    ...layoutComputed,
  },
  created: () => {
    document.body.removeAttribute('data-layout', 'horizontal');
    document.body.removeAttribute('data-topbar', 'dark');
    document.body.removeAttribute('data-layout-size', 'boxed');
    document.body.classList.remove('auth-body-bg');
  },
  methods: {
    toggleMenu() {
      document.body.classList.toggle('sidebar-enable');

      if (window.screen.width >= 992) {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove('sidebar-enable');
          document.body.classList.remove('vertical-collpsed');
        });
        document.body.classList.toggle('vertical-collpsed');
      } else {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove('sidebar-enable');
        });
        document.body.classList.remove('vertical-collpsed');
      }
      this.isMenuCondensed = !this.isMenuCondensed;
    },
    toggleRightSidebar() {
      document.body.classList.toggle('right-bar-enabled');
    },
    hideRightSidebar() {
      document.body.classList.remove('right-bar-enabled');
    },
  },
  mounted() {
    if (this.loader === true) {
      document.getElementById('preloader').style.display = 'block';
      document.getElementById('status').style.display = 'block';

      setTimeout(() => {
        document.getElementById('preloader').style.display = 'none';
        document.getElementById('status').style.display = 'none';
      }, 2500);
    } else {
      document.getElementById('preloader').style.display = 'none';
      document.getElementById('status').style.display = 'none';
    }
  },
};
</script>

<template>
  <div>
    <div id="preloader">
      <div id="status">
        <div class="spinner-chase">
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
        </div>
      </div>
    </div>
    <div id="layout-wrapper">
      <NavBar/>
      <SideBar :is-condensed="isMenuCondensed" :type="leftSidebarType" :width="layoutWidth"/>
      <!-- ============================================================== -->
      <!-- Start Page Content here -->
      <!-- ============================================================== -->

      <div class="main-content">
        <div class="page-content">
          <!-- Start Content-->
          <div class="container-fluid">
            <slot/>
          </div>
        </div>
        <Footer/>
      </div>
      <RightBar/>
    </div>
  </div>
</template>
