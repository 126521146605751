<script>
import axios from 'axios';
// import {SimpleBar} from "simplebar-vue3";

import { authComputed, authMethods } from '@/state/helpers';
import { logOutMessage } from '@/components/widgets/swalUtils';

/**
 * Nav-bar Component
 */
export default {
  data() {
    return {
      lang: 'en',
      text: null,
      flag: null,
      value: null,
    };
  },
  components: {},
  mounted() {
  },
  methods: {
    ...authMethods,
    async logout() {
      const answer = await logOutMessage();

      if (answer.isConfirmed) {
        this.logOut();
        this.$router.go();
      }
    },
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    initFullScreen() {
      document.body.classList.toggle('fullscreen-enable');
      if (
        !document.fullscreenElement
        /* alternative standard method */ && !document.mozFullScreenElement
        && !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT,
          );
        }
      } else if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    },

    logoutUser() {
      // eslint-disable-next-line no-unused-vars
      axios.get('http://127.0.0.1:8000/api/logout').then((res) => {
        this.$router.push({
          name: 'default',
        });
      });
    },
  },
  computed: {
    ...authComputed,
  },
};
</script>

<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <router-link class="logo" to="/">
            <span class="logo-sm">
              <img alt="Logo" height="22" src="@/assets/images/logo.svg"/>
            </span>
            <span class="logo-lg">
              <img alt="Logo" class="bg-white mt-3 px-2 shadow-lg rounded-2"
                   src="@/assets/images/logo.png"
                   width="200"/>
            </span>
          </router-link>
        </div>

        <button
          id="vertical-menu-btn"
          class="btn btn-sm px-3 font-size-16 header-item"
          type="button"
          @click="toggleMenu"
        >
          <i class="fa fa-fw fa-bars"></i>
        </button>

        <!-- App Search-->
        <form class="app-search d-none d-lg-block">
          <div class="position-relative">
            <label for="search">
              <input
                :placeholder="$t('navbar.search.text')"
                class="form-control"
                type="text"
              />
            </label>
            <span class="bx bx-search-alt"></span>
          </div>
        </form>
      </div>

      <div class="d-flex">
        <b-dropdown
          class="d-inline-block d-lg-none ms-2"
          menu-class="dropdown-menu-lg p-0"
          right
          toggle-class="header-item noti-icon"
          variant="black"
        >
          <template v-slot:button-content>
            <i class="mdi mdi-magnify"></i>
          </template>

          <form class="p-3">
            <div class="form-group m-0">
              <div class="input-group">
                <input
                  aria-label="Recipient's username"
                  class="form-control"
                  placeholder="Search ..."
                  type="text"
                />
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit">
                    <i class="mdi mdi-magnify"></i>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </b-dropdown>

        <!--        <b-dropdown-->
        <!--            class="d-none d-lg-inline-block noti-icon"-->
        <!--            menu-class="dropdown-menu-lg dropdown-menu-end"-->
        <!--            right-->
        <!--            toggle-class="header-item"-->
        <!--            variant="black"-->
        <!--        >-->
        <!--          <template v-slot:button-content>-->
        <!--            <i class="bx bx-customize"></i>-->
        <!--          </template>-->

        <!--          <div class="px-lg-2">-->
        <!--            <div class="row no-gutters">-->
        <!--              <div class="col">-->
        <!--                <a class="dropdown-icon-item" href="javascript: void(0);">-->
        <!--                  <img src="@/assets/images/brands/github.png" alt="Github"/>-->
        <!--                  <span>{{ $t("navbar.dropdown.site.list.github") }}</span>-->
        <!--                </a>-->
        <!--              </div>-->
        <!--              <div class="col">-->
        <!--                <a class="dropdown-icon-item" href="javascript: void(0);">-->
        <!--                  <img-->
        <!--                      src="@/assets/images/brands/bitbucket.png"-->
        <!--                      alt="bitbucket"-->
        <!--                  />-->
        <!--                  <span>{{ $t("navbar.dropdown.site.list.github") }}</span>-->
        <!--                </a>-->
        <!--              </div>-->
        <!--              <div class="col">-->
        <!--                <a class="dropdown-icon-item" href="javascript: void(0);">-->
        <!--                  <img-->
        <!--                      src="@/assets/images/brands/dribbble.png"-->
        <!--                      alt="dribbble"-->
        <!--                  />-->
        <!--                  <span>{{ $t("navbar.dropdown.site.list.dribbble") }}</span>-->
        <!--                </a>-->
        <!--              </div>-->
        <!--            </div>-->

        <!--            <div class="row no-gutters">-->
        <!--              <div class="col">-->
        <!--                <a class="dropdown-icon-item" href="javascript: void(0);">-->
        <!--                  <img src="@/assets/images/brands/dropbox.png" alt="dropbox"/>-->
        <!--                  <span>{{ $t("navbar.dropdown.site.list.dropbox") }}</span>-->
        <!--                </a>-->
        <!--              </div>-->
        <!--              <div class="col">-->
        <!--                <a class="dropdown-icon-item" href="javascript: void(0);">-->
        <!--                  <img-->
        <!--                      src="@/assets/images/brands/mail_chimp.png"-->
        <!--                      alt="mail_chimp"-->
        <!--                  />-->
        <!--                  <span>{{ $t("navbar.dropdown.site.list.mailchimp") }}</span>-->
        <!--                </a>-->
        <!--              </div>-->
        <!--              <div class="col">-->
        <!--                <a class="dropdown-icon-item" href="javascript: void(0);">-->
        <!--                  <img src="@/assets/images/brands/slack.png" alt="slack"/>-->
        <!--                  <span>{{ $t("navbar.dropdown.site.list.slack") }}</span>-->
        <!--                </a>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </b-dropdown>-->

        <!--        <div class="dropdown d-none d-lg-inline-block ms-1">-->
        <!--          <button-->
        <!--              type="button"-->
        <!--              class="btn header-item noti-icon"-->
        <!--              @click="initFullScreen"-->
        <!--          >-->
        <!--            <i class="bx bx-fullscreen"></i>-->
        <!--          </button>-->
        <!--        </div>-->

        <!--        <b-dropdown-->
        <!--            right-->
        <!--            menu-class="dropdown-menu-lg p-0 dropdown-menu-end"-->
        <!--            toggle-class="header-item noti-icon"-->
        <!--            variant="black"-->
        <!--        >-->
        <!--          <template v-slot:button-content>-->
        <!--            <i class="bx bx-bell bx-tada"></i>-->
        <!--            <span class="badge bg-danger rounded-pill">{{-->
        <!--                $t("navbar.dropdown.notification.badge")-->
        <!--              }}</span>-->
        <!--          </template>-->

        <!--          <div class="p-3">-->
        <!--            <div class="row align-items-center">-->
        <!--              <div class="col">-->
        <!--                <h6 class="m-0">-->
        <!--                  {{ $t("navbar.dropdown.notification.text") }}-->
        <!--                </h6>-->
        <!--              </div>-->
        <!--              <div class="col-auto">-->
        <!--                <a href="#" class="small">{{-->
        <!--                    $t("navbar.dropdown.notification.subtext")-->
        <!--                  }}</a>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--          <SimpleBar style="max-height: 230px">-->
        <!--            <a href="javascript: void(0);" class="text-reset notification-item">-->
        <!--              <div class="d-flex">-->
        <!--                <div class="avatar-xs me-3">-->
        <!--                  <span-->
        <!--                      class="avatar-title bg-primary rounded-circle font-size-16"-->
        <!--                  >-->
        <!--                    <i class="bx bx-cart"></i>-->
        <!--                  </span>-->
        <!--                </div>-->
        <!--                <div class="flex-grow-1">-->
        <!--                  <h6 class="mt-0 mb-1">-->
        <!--                    {{ $t("navbar.dropdown.notification.order.title") }}-->
        <!--                  </h6>-->
        <!--                  <div class="font-size-12 text-muted">-->
        <!--                    <p class="mb-1">-->
        <!--                      {{ $t("navbar.dropdown.notification.order.text") }}-->
        <!--                    </p>-->
        <!--                    <p class="mb-0">-->
        <!--                      <i class="mdi mdi-clock-outline"></i>-->
        <!--                      {{ $t("navbar.dropdown.notification.order.time") }}-->
        <!--                    </p>-->
        <!--                  </div>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </a>-->
        <!--            <a href="javascript: void(0);" class="text-reset notification-item">-->
        <!--              <div class="d-flex">-->
        <!--                <img-->
        <!--                    src="@/assets/images/users/avatar-3.jpg"-->
        <!--                    class="me-3 rounded-circle avatar-xs"-->
        <!--                    alt="user-pic"-->
        <!--                />-->
        <!--                <div class="flex-grow-1">-->
        <!--                  <h6 class="mt-0 mb-1">-->
        <!--                    {{ $t("navbar.dropdown.notification.james.title") }}-->
        <!--                  </h6>-->
        <!--                  <div class="font-size-12 text-muted">-->
        <!--                    <p class="mb-1">-->
        <!--                      {{ $t("navbar.dropdown.notification.james.text") }}-->
        <!--                    </p>-->
        <!--                    <p class="mb-0">-->
        <!--                      <i class="mdi mdi-clock-outline"></i>-->
        <!--                      {{ $t("navbar.dropdown.notification.james.time") }}-->
        <!--                    </p>-->
        <!--                  </div>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </a>-->
        <!--            <a href="javascript: void(0);" class="text-reset notification-item">-->
        <!--              <div class="d-flex">-->
        <!--                <div class="avatar-xs me-3">-->
        <!--                  <span-->
        <!--                      class="avatar-title bg-success rounded-circle font-size-16"-->
        <!--                  >-->
        <!--                    <i class="bx bx-badge-check"></i>-->
        <!--                  </span>-->
        <!--                </div>-->
        <!--                <div class="flex-grow-1">-->
        <!--                  <h6 class="mt-0 mb-1">-->
        <!--                    {{ $t("navbar.dropdown.notification.item.title") }}-->
        <!--                  </h6>-->
        <!--                  <div class="font-size-12 text-muted">-->
        <!--                    <p class="mb-1">-->
        <!--                      {{ $t("navbar.dropdown.notification.item.text") }}-->
        <!--                    </p>-->
        <!--                    <p class="mb-0">-->
        <!--                      <i class="mdi mdi-clock-outline"></i>-->
        <!--                      {{ $t("navbar.dropdown.notification.item.time") }}-->
        <!--                    </p>-->
        <!--                  </div>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </a>-->
        <!--            <a href="javascript: void(0);" class="text-reset notification-item">-->
        <!--              <div class="d-flex">-->
        <!--                <img-->
        <!--                    src="@/assets/images/users/avatar-4.jpg"-->
        <!--                    class="me-3 rounded-circle avatar-xs"-->
        <!--                    alt="user-pic"-->
        <!--                />-->
        <!--                <div class="flex-grow-1">-->
        <!--                  <h6 class="mt-0 mb-1">-->
        <!--                    {{ $t("navbar.dropdown.notification.salena.title") }}-->
        <!--                  </h6>-->
        <!--                  <div class="font-size-12 text-muted">-->
        <!--                    <p class="mb-1">-->
        <!--                      {{ $t("navbar.dropdown.notification.salena.text") }}-->
        <!--                    </p>-->
        <!--                    <p class="mb-0">-->
        <!--                      <i class="mdi mdi-clock-outline"></i>-->
        <!--                      {{ $t("navbar.dropdown.notification.salena.time") }}-->
        <!--                    </p>-->
        <!--                  </div>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </a>-->
        <!--          </SimpleBar>-->
        <!--          <div class="p-2 border-top d-grid">-->
        <!--            <a-->
        <!--                class="btn btn-sm btn-link font-size-14 text-center"-->
        <!--                href="javascript:void(0)"-->
        <!--            >-->
        <!--              <i class="mdi mdi-arrow-right-circle me-1"></i>-->
        <!--              {{ $t("navbar.dropdown.notification.button") }}-->
        <!--            </a>-->
        <!--          </div>-->
        <!--        </b-dropdown>-->

        <b-dropdown
          menu-class="dropdown-menu-end"
          right
          toggle-class="header-item"
          variant="black"
        >
          <template v-slot:button-content>
            <img
              alt="Header Avatar"
              class="rounded-circle header-profile-user"
              src="@/assets/images/male_avatar.svg"
            />
            <span class="d-none d-xl-inline-block ms-1">
              <div v-if="currentUser">
                {{ currentUser.first_name }} {{ currentUser.last_name }}
              </div>
            </span>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </template>
          <!-- item-->
          <b-dropdown-item>
            <router-link v-slot="{ navigate }" custom to="/contacts/profile">
              <span @click="navigate" @keypress.enter="navigate">
                <i class="bx bx-user font-size-16 align-middle me-1"></i>
                {{ $t('navbar.dropdown.henry.list.profile') }}
              </span>
            </router-link>
          </b-dropdown-item>
          <b-dropdown-item href="javascript: void(0);">
            <i class="bx bx-wallet font-size-16 align-middle me-1"></i>
            {{ $t('navbar.dropdown.henry.list.mywallet') }}
          </b-dropdown-item>
          <b-dropdown-item class="d-block" href="javascript: void(0);">
            <span class="badge bg-success float-end">11</span>
            <i class="bx bx-wrench font-size-16 align-middle me-1"></i>
            {{ $t('navbar.dropdown.henry.list.settings') }}
          </b-dropdown-item>
          <b-dropdown-item href="javascript: void(0);">
            <i class="bx bx-lock-open font-size-16 align-middle me-1"></i>
            {{ $t('navbar.dropdown.henry.list.lockscreen') }}
          </b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <a class="dropdown-item text-danger" href="#" @click.prevent="logout">
            <i
              class="bx bx-power-off font-size-16 align-middle me-1 text-danger"
            ></i>
            {{ $t('navbar.dropdown.henry.list.logout') }}
          </a>
        </b-dropdown>

        <div class="dropdown d-inline-block">
          <button
            class="btn header-item noti-icon right-bar-toggle toggle-right"
            type="button"
            @click="toggleRightSidebar"
          >
            <i class="bx bx-cog bx-spin toggle-right"></i>
          </button>
        </div>
      </div>
    </div>
  </header>
</template>
