<script>
import { SimpleBar } from 'simplebar-vue3';
import { layoutComputed } from '@/state/helpers';
import { logOutMessage } from '@/components/widgets/swalUtils';

/**
 * Horizontal-topbar component
 */
export default {
  props: {
    type: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...layoutComputed,
  },
  components: {
    SimpleBar,
  },
  data() {
    return {};
  },
  methods: {
    async logout() {
      const answer = await logOutMessage();

      if (answer.isConfirmed) {
        this.logOut();
        this.$router.go();
      }
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    toggleMenu() {
      const element = document.getElementById('topnav-menu-content');
      element.classList.toggle('show');
    },
    initFullScreen() {
      document.body.classList.toggle('fullscreen-enable');
      if (
        !document.fullscreenElement
        /* alternative standard method */ && !document.mozFullScreenElement
        && !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT,
          );
        }
      } else if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    },
    setLanguage(locale, country, flag) {
      this.current_language = locale;
      this.text = country;
      this.flag = flag;
      // i18n.locale = locale;
    },
  },
  watch: {
    type: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case 'dark':
              document.body.setAttribute('data-topbar', 'dark');
              document.body.removeAttribute('data-layout-scrollable');
              break;
            case 'light':
              document.body.setAttribute('data-topbar', 'light');
              document.body.removeAttribute('data-layout-size', 'boxed');
              document.body.removeAttribute('data-layout-scrollable');
              break;
            case 'colored':
              document.body.setAttribute('data-topbar', 'colored');
              document.body.removeAttribute('data-layout-size', 'boxed');
              break;
            default:
              document.body.setAttribute('data-topbar', 'dark');
              break;
          }
        }
      },
    },
    width: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case 'boxed':
              document.body.setAttribute('data-layout-size', 'boxed');
              document.body.removeAttribute('data-layout-scrollable');
              break;
            case 'fluid':
              document.body.setAttribute('data-layout-mode', 'fluid');
              document.body.removeAttribute('data-layout-scrollable');
              document.body.removeAttribute('data-layout-size');
              break;
            case 'scrollable':
              document.body.setAttribute('data-layout-scrollable', 'true');
              document.body.removeAttribute('data-layout-mode');
              document.body.removeAttribute('data-layout-size');
              break;
            default:
              document.body.setAttribute('data-layout-mode', 'fluid');
              break;
          }
        }
      },
    },
  },
};
</script>

<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <router-link class="logo logo-dark" to="/">
            <span class="logo-sm">
              <img alt="Logo" height="22" src="@/assets/images/logo.svg"/>
            </span>
            <span class="logo-lg">
              <img alt="Logo" height="17" src="@/assets/images/logo-dark.png"/>
            </span>
          </router-link>

          <router-link class="logo logo-light" to="/">
            <span class="logo-sm">
              <img alt="Logo" height="22" src="@/assets/images/logo-light.svg"/>
            </span>
            <span class="logo-lg">
              <img alt="Logo" height="19" src="@/assets/images/logo-light.png"/>
            </span>
          </router-link>
        </div>

        <button
          id="toggle"
          class="btn btn-sm me-2 font-size-16 d-lg-none header-item"
          type="button"
          @click="toggleMenu"
        >
          <i class="fa fa-fw fa-bars"></i>
        </button>

        <!-- App Search-->
        <form class="app-search d-none d-lg-block">
          <div class="position-relative">
            <label for="search">
              <input class="form-control" placeholder="Search..." type="text"/>
            </label>
            <span class="bx bx-search-alt"></span>
          </div>
        </form>

        <b-dropdown
          class="dropdown-mega d-none d-lg-block ms-2"
          menu-class="dropdown-megamenu"
          toggle-class="header-item"
          variant="black"
        >
          <template v-slot:button-content>
            {{ $t('navbar.dropdown.megamenu.text') }}
            <i class="mdi mdi-chevron-down"></i>
          </template>

          <div class="row">
            <div class="col-sm-8">
              <div class="row">
                <div class="col-md-4">
                  <h5 class="font-size-14 mt-0">
                    {{ $t('navbar.dropdown.megamenu.uicontent.title') }}
                  </h5>
                  <ul class="list-unstyled megamenu-list">
                    <li>
                      <a href="javascript:void(0);">{{
                          $t('navbar.dropdown.megamenu.uicontent.list.lightbox')
                        }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                          $t(
                            'navbar.dropdown.megamenu.uicontent.list.rangeslider',
                          )
                        }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                          $t('navbar.dropdown.megamenu.uicontent.list.sweetalert')
                        }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                          $t('navbar.dropdown.megamenu.uicontent.list.rating')
                        }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                          $t('navbar.dropdown.megamenu.uicontent.list.forms')
                        }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                          $t('navbar.dropdown.megamenu.uicontent.list.tables')
                        }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                          $t('navbar.dropdown.megamenu.uicontent.list.charts')
                        }}</a>
                    </li>
                  </ul>
                </div>

                <div class="col-md-4">
                  <h5 class="font-size-14 mt-0">
                    {{ $t('navbar.dropdown.megamenu.application.title') }}
                  </h5>
                  <ul class="list-unstyled megamenu-list">
                    <li>
                      <a href="javascript:void(0);">{{
                          $t(
                            'navbar.dropdown.megamenu.application.list.ecommerce',
                          )
                        }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                          $t('navbar.dropdown.megamenu.application.list.calendar')
                        }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                          $t('navbar.dropdown.megamenu.application.list.email')
                        }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                          $t('navbar.dropdown.megamenu.application.list.projects')
                        }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                          $t('navbar.dropdown.megamenu.application.list.tasks')
                        }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                          $t('navbar.dropdown.megamenu.application.list.contacts')
                        }}</a>
                    </li>
                  </ul>
                </div>

                <div class="col-md-4">
                  <h5 class="font-size-14 mt-0">
                    {{ $t('navbar.dropdown.megamenu.extrapages.title') }}
                  </h5>
                  <ul class="list-unstyled megamenu-list">
                    <li>
                      <a href="javascript:void(0);">{{
                          $t(
                            'navbar.dropdown.megamenu.extrapages.list.lightsidebar',
                          )
                        }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                          $t(
                            'navbar.dropdown.megamenu.extrapages.list.compactsidebar',
                          )
                        }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                          $t(
                            'navbar.dropdown.megamenu.extrapages.list.horizontallayout',
                          )
                        }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                          $t(
                            'navbar.dropdown.megamenu.extrapages.list.maintenance',
                          )
                        }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                          $t(
                            'navbar.dropdown.megamenu.extrapages.list.comingsoon',
                          )
                        }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                          $t('navbar.dropdown.megamenu.extrapages.list.timeline')
                        }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                          $t('navbar.dropdown.megamenu.extrapages.list.faqs')
                        }}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="row">
                <div class="col-sm-6">
                  <h5 class="font-size-14 mt-0">
                    {{ $t('navbar.dropdown.megamenu.uicontent.title') }}
                  </h5>
                  <ul class="list-unstyled megamenu-list">
                    <li>
                      <a href="javascript:void(0);">{{
                          $t('navbar.dropdown.megamenu.uicontent.list.lightbox')
                        }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                          $t(
                            'navbar.dropdown.megamenu.uicontent.list.rangeslider',
                          )
                        }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                          $t('navbar.dropdown.megamenu.uicontent.list.sweetalert')
                        }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                          $t('navbar.dropdown.megamenu.uicontent.list.rating')
                        }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                          $t('navbar.dropdown.megamenu.uicontent.list.forms')
                        }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                          $t('navbar.dropdown.megamenu.uicontent.list.tables')
                        }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                          $t('navbar.dropdown.megamenu.uicontent.list.charts')
                        }}</a>
                    </li>
                  </ul>
                </div>

                <div class="col-sm-5">
                  <div>
                    <img
                      alt="menu"
                      class="img-fluid mx-auto d-block"
                      src="@/assets/images/megamenu-img.png"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-dropdown>
      </div>

      <div class="d-flex">
        <b-dropdown
          class="d-inline-block d-lg-none ms-2"
          menu-class="dropdown-menu-lg p-0 dropdown-menu-end"
          right
          toggle-class="header-item noti-icon"
          variant="black"
        >
          <template v-slot:button-content>
            <i class="mdi mdi-magnify"></i>
          </template>

          <form class="p-3">
            <div class="form-group m-0">
              <div class="input-group">
                <input
                  aria-label="Recipient's username"
                  class="form-control"
                  placeholder="Search ..."
                  type="text"
                />
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit">
                    <i class="mdi mdi-magnify"></i>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </b-dropdown>
        <b-dropdown
          class="d-none d-lg-inline-block noti-icon"
          menu-class="dropdown-menu-lg dropdown-menu-end"
          right
          toggle-class="header-item"
          variant="black"
        >
          <template v-slot:button-content>
            <i class="bx bx-customize"></i>
          </template>

          <div class="px-lg-2">
            <div class="row no-gutters">
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img alt="Github" src="@/assets/images/brands/github.png"/>
                  <span>{{ $t('navbar.dropdown.site.list.github') }}</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img
                    alt="bitbucket"
                    src="@/assets/images/brands/bitbucket.png"
                  />
                  <span>{{ $t('navbar.dropdown.site.list.github') }}</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img
                    alt="dribbble"
                    src="@/assets/images/brands/dribbble.png"
                  />
                  <span>{{ $t('navbar.dropdown.site.list.dribbble') }}</span>
                </a>
              </div>
            </div>

            <div class="row no-gutters">
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img alt="dropbox" src="@/assets/images/brands/dropbox.png"/>
                  <span>{{ $t('navbar.dropdown.site.list.dropbox') }}</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img
                    alt="mail_chimp"
                    src="@/assets/images/brands/mail_chimp.png"
                  />
                  <span>{{ $t('navbar.dropdown.site.list.mailchimp') }}</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img alt="slack" src="@/assets/images/brands/slack.png"/>
                  <span>{{ $t('navbar.dropdown.site.list.slack') }}</span>
                </a>
              </div>
            </div>
          </div>
        </b-dropdown>

        <div class="dropdown d-none d-lg-inline-block ms-1">
          <button
            class="btn header-item noti-icon"
            type="button"
            @click="initFullScreen"
          >
            <i class="bx bx-fullscreen"></i>
          </button>
        </div>

        <b-dropdown
          menu-class="dropdown-menu-lg p-0 dropdown-menu-end"
          toggle-class="header-item noti-icon"
          variant="black"
        >
          <template v-slot:button-content>
            <i class="bx bx-bell bx-tada"></i>
            <span class="badge bg-danger rounded-pill">{{
                $t('navbar.dropdown.notification.badge')
              }}</span>
          </template>

          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">
                  {{ $t('navbar.dropdown.notification.text') }}
                </h6>
              </div>
              <div class="col-auto">
                <a class="small" href="#">{{
                    $t('navbar.dropdown.notification.subtext')
                  }}</a>
              </div>
            </div>
          </div>
          <SimpleBar style="max-height: 230px">
            <a class="text-reset notification-item" href="javascript: void(0);">
              <div class="d-flex">
                <div class="avatar-xs me-3">
                  <span
                    class="avatar-title bg-primary rounded-circle font-size-16"
                  >
                    <i class="bx bx-cart"></i>
                  </span>
                </div>
                <div class="flex-grow-1">
                  <h6 class="mt-0 mb-1">
                    {{ $t('navbar.dropdown.notification.order.title') }}
                  </h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      {{ $t('navbar.dropdown.notification.order.text') }}
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>
                      {{ $t('navbar.dropdown.notification.order.time') }}
                    </p>
                  </div>
                </div>
              </div>
            </a>
            <a class="text-reset notification-item" href="javascript: void(0);">
              <div class="d-flex">
                <img
                  alt="user-pic"
                  class="me-3 rounded-circle avatar-xs"
                  src="@/assets/images/users/avatar-3.jpg"
                />
                <div class="flex-grow-1">
                  <h6 class="mt-0 mb-1">
                    {{ $t('navbar.dropdown.notification.james.title') }}
                  </h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      {{ $t('navbar.dropdown.notification.james.text') }}
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>
                      {{ $t('navbar.dropdown.notification.james.time') }}
                    </p>
                  </div>
                </div>
              </div>
            </a>
            <a class="text-reset notification-item" href="javascript: void(0);">
              <div class="d-flex">
                <div class="avatar-xs me-3">
                  <span class="avatar-title bg-success rounded-circle font-size-16">
                    <i class="bx bx-badge-check"></i>
                  </span>
                </div>
                <div class="flex-grow-1">
                  <h6 class="mt-0 mb-1">
                    {{ $t('navbar.dropdown.notification.item.title') }}
                  </h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      {{ $t('navbar.dropdown.notification.item.text') }}
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>
                      {{ $t('navbar.dropdown.notification.item.time') }}
                    </p>
                  </div>
                </div>
              </div>
            </a>
            <a class="text-reset notification-item" href="javascript: void(0);">
              <div class="d-flex">
                <img
                  alt="user-pic"
                  class="me-3 rounded-circle avatar-xs"
                  src="@/assets/images/users/avatar-4.jpg"
                />
                <div class="flex-grow-1">
                  <h6 class="mt-0 mb-1">
                    {{ $t('navbar.dropdown.notification.salena.title') }}
                  </h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      {{ $t('navbar.dropdown.notification.salena.text') }}
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>
                      {{ $t('navbar.dropdown.notification.salena.time') }}
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </SimpleBar>
          <div class="p-2 border-top d-grid">
            <a
              class="btn btn-sm btn-link font-size-14 text-center"
              href="javascript:void(0)"
            >
              <i class="mdi mdi-arrow-right-circle me-1"></i>
              <span key="t-view-more">
                {{ $t('navbar.dropdown.notification.button') }}</span
              >
            </a>
          </div>
        </b-dropdown>

        <b-dropdown right toggle-class="header-item" variant="black">
          <template v-slot:button-content>
            <img
              alt="Header Avatar"
              class="rounded-circle header-profile-user"
              src="@/assets/images/users/avatar-1.jpg"
            />
            <span class="d-none d-xl-inline-block ms-1">{{
                $t('navbar.dropdown.henry.text')
              }}</span>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </template>
          <!-- item-->
          <b-dropdown-item>
            <router-link v-slot="{ navigate }" custom to="/contacts/profile">
            <span @click="navigate" @keypress.enter="navigate">
              <i class="bx bx-user font-size-16 align-middle me-1"></i>
              {{ $t('navbar.dropdown.henry.list.profile') }}
              </span>
            </router-link>
          </b-dropdown-item>
          <b-dropdown-item href="javascript: void(0);">
            <i class="bx bx-wallet font-size-16 align-middle me-1"></i>
            {{ $t('navbar.dropdown.henry.list.mywallet') }}
          </b-dropdown-item>
          <b-dropdown-item class="d-block" href="javascript: void(0);">
            <span class="badge bg-success float-end">11</span>
            <i class="bx bx-wrench font-size-16 align-middle me-1"></i>
            {{ $t('navbar.dropdown.henry.list.settings') }}
          </b-dropdown-item>
          <b-dropdown-item href="javascript: void(0);">
            <i class="bx bx-lock-open font-size-16 align-middle me-1"></i>
            {{ $t('navbar.dropdown.henry.list.lockscreen') }}
          </b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <a class="dropdown-item text-danger" href="#" @click="logout">
            <i
              class="bx bx-power-off font-size-16 align-middle me-1 text-danger"
            ></i>
            {{ $t('navbar.dropdown.henry.list.logout') }}
          </a>
        </b-dropdown>

        <div class="dropdown d-inline-block">
          <button
            class="btn header-item noti-icon right-bar-toggle toggle-right"
            type="button"
            @click="toggleRightSidebar"
          >
            <i class="bx bx-cog bx-spin toggle-right"></i>
          </button>
        </div>
      </div>
    </div>
  </header>
</template>
